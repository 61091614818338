<template>
  <div class="flex-col">
    <div class="half-wrapper">
      <v-img
        class="mx-auto image error-img"
        :src= "
          selectedLocale.key == 'es'
              ? require('@/assets/desktop-redeem-es.png')
              : require('@/assets/desktop-redeem.png')
        "
       
        contain
        :eager="true"
      />
    </div>
    <div class="half-wrapper text-center flex-col">
      <v-img
        class="mx-auto image"
        src="@/assets/error.png"
       
        width="30%"
        contain
        :eager="true"
      />
      <span class="text-h6 primary--text font-weight-bold text-center mt-5">{{
        title
      }}</span>
      <span
        class="text-h7 secondaryGrey--text font-weight-bold text-center mt-5"
        >{{ subTitle }}</span
      >
    </div>
  </div>
</template>

<style>
.error-img {
  margin-bottom: 5em;
}

.half-wrapper {
  width: 50%;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-center {
  text-align: center;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { getStringForKey } from "@/translations/utilities";

export default {
  name: "PreRegisterAuthed",
  components: {},
  data() {
    return {};
  },
  watch: {},
  computed: {
    ...mapGetters({
      preAuthRegistrationError: "account/preAuthRegistrationError",
      showLoading: "account/showLoading",
      selectedLocale: "account/getSelectedLocale",
    }),
    title() {
      return getStringForKey(this.selectedLocale, "createAccountErrorTitle");
    },
    subTitle() {
      return getStringForKey(this.selectedLocale, "createAccountErrorSubtitle");
    },
  },
  methods: {
    ...mapActions({
      preAuthRegistrationError: "account/preAuthRegistrationError",
    }),
    stringForKey(key) {
      return getStringForKey(this.selectedLocale, key);
    },
  },
  mounted() {
    let tokenId = this.$route.params.tokenId;
    this.registerWithTokenId({ tokenId: tokenId });
  },
};
</script>
