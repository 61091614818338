<template>
  <div class="flex-row">
    <div class="half-wrapper">
      <v-img
        class="mx-auto image"
        :src= "
          selectedLocale.key == 'es'
              ? require('@/assets/desktop-redeem-es.png')
              : require('@/assets/desktop-redeem.png')
        "
      
        width="40vw"
        contain
        :eager="true"
      />
    </div>
    <div class="half-wrapper text-center flex-col">
      <v-img
        class="mx-auto image"
        src="@/assets/error.png"
       
        width="15%"
        contain
        :eager="true"
      />
      <span class="text-h5 primary--text font-weight-bold text-center mt-5">{{
        title
      }}</span>
      <span
        style="width: 40%"
        class="text-h6 secondaryGrey--text font-weight-bold text-center mt-5"
        >{{ subTitle }}</span
      >
    </div>
  </div>
</template>

<style>
.half-wrapper {
  width: 50%;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-center {
  text-align: center;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { getStringForKey } from "@/translations/utilities";

export default {
  name: "PreRegisterAuthed",
  components: {},
  data() {
    return {};
  },
  watch: {},
  computed: {
    ...mapGetters({
      preAuthRegistrationError: "account/preAuthRegistrationError",
      showLoading: "account/showLoading",
      selectedLocale: "account/getSelectedLocale",
    }),
    title() {
      return getStringForKey(this.selectedLocale, "createAccountErrorTitle");
    },
    subTitle() {
      return getStringForKey(this.selectedLocale, "createAccountErrorSubtitle");
    },
  },
  methods: {
    ...mapActions({
      registerWithTokenId: "account/registerWithTokenId",
    }),
    stringForKey(key) {
      return getStringForKey(this.selectedLocale, key);
    },
  },
  mounted() {
    let tokenId = this.$route.params.tokenId;
    this.registerWithTokenId({ tokenId: tokenId });
  },
};
</script>
